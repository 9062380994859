import { Box, Button, Grid, List, ListItem } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAuth, withAuthenticationRequired } from "react-oidc-context";
import { useScreenSize } from "../common/hooks/useScreenSize";

function Landing() {
  const auth = useAuth();

  const { t } = useTranslation();

  const { small, medium } = useScreenSize();

  const buttonStyle = {
    width: '100%',
    height: 60,
    borderRadius: 20,
  };

  React.useEffect(() => {
    if (auth.isAuthenticated && !window.location.pathname.startsWith('/app')) {
      window.location.href = '/app';
    }
  }, [auth]);

  const image = (
    <Grid item xs={12} sm={6}>
      <Box sx={{ my: 2, mx: 2 }} style={{
        marginLeft: 'auto',
        marginRight: 'auto',
      }}>
        <img src="/imgs/landing.png" style={{
          maxWidth: '500px',
          width: 'calc(100% - 50px)',
          height: 'auto',
          marginLeft: 'auto',
          marginRight: 'auto',
        }} />
      </Box>
    </Grid>
  );

  const text = (
    <Grid item xs={12} sm={6}>
      <Box sx={{ my: medium ? 10 : 2, mx: 2 }} style={{
        maxWidth: '350px',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}>
        <h1 style={{
          textAlign: 'center',
          marginBlockEnd: 0,
          marginBlockStart: 0,
        }}>
          {t('Reduce the costs')}<br />
          {t('improve the efficiency')}
        </h1>
        <p style={{
          textAlign: 'center',
          marginBlockStart: 0,
          color: 'var(--gray-2)',
        }}>
          {t('Perform measurements, analysis and reports with the DROMTpg photogrammetry app')}
        </p>
        <List>
          <ListItem disablePadding>
            <Button variant="contained" style={buttonStyle} onClick={() => void auth.signinRedirect()}>
              {t('Log in')}
            </Button>
          </ListItem>
        </List>
      </Box>
    </Grid>
  );

  return (
    <React.Fragment>
      <Grid container spacing={0} style={{
        margin: small ? '20px' : '0',
      }}>
        { !small && <React.Fragment>
          { image }
          { text }
        </React.Fragment> }
        { small && <React.Fragment>
          { text }
          { image }
        </React.Fragment> }
      </Grid>
    </React.Fragment>
  );
}

export default Landing;

export const AuthLanding = withAuthenticationRequired(Landing);
